import React, {useEffect} from "react";
import 'components-jqueryui';
import $ from "jquery";
import 'select2';
import feather from "feather-icons";
import {GET_ZONE_PRODUCT_URL, GET_ZONE_USER} from "../../api";
import {capitalize} from "../../utils/capitalize";
import {useSelector} from "react-redux";
import formatNumber from "../../utils/formatNumber";
const jQuery = window.$;

let addresses = [];
let amount = 0;

const Wizard = ({}) => {

    const auth = useSelector((state) => state.auth.user);
    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        $(function () {
            'use strict';
            feather.replace();
        });

        jQuery('#wizard2').steps({
            headerTag: 'h3',
            bodyTag: 'section',
            autoFocus: true,
            titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>',
            onStepChanging: function (event, currentIndex, newIndex) {
                if(currentIndex < newIndex) {
                    // Step 1 form validation
                    if(currentIndex === 0) {
                        let customer = $('#user-select2').parsley();
                        let address = $('#address').parsley();
                        let product = $('#product-select2').parsley();

                        if(customer.isValid() && address.isValid() && product.isValid()) {
                            let total_month = $("#total-month").val();
                            let total_bin = $("#total-bin").val();
                            $("#total-amount").text(`${auth.user.country.currency.symbol}${amount * total_month * total_bin}`);
                            return true;
                        } else {
                            customer.validate();
                            address.validate();
                            product.validate();
                        }
                    }

                    // Step 2 form validation
                    if(currentIndex === 1) {
                        let date = $('#date').parsley();
                        let total_month = $("#total-month").parsley();
                        let total_bin = $("#total-bin").parsley();
                        let mode_payment = $('#mode-payment').parsley();

                        if(date.isValid() && total_month.isValid() && total_bin.isValid() && mode_payment.isValid()) {
                            let total_month = $("#total-month").val();
                            let total_bin = $("#total-bin").val();
                            let waste_type = $('#waste-type').val();
                            let date = $('#date').val();

                            $("#tx-total-month").text(total_month);
                            $("#tx-waste-type").text(waste_type);
                            $("#tx-total-bin").text(total_bin);
                            $("#tx-date").text(date);

                            $("#tx-total-amount").text(`${auth.user.country.currency.symbol}${formatNumber(amount * total_month * total_bin)}`);

                            return true;
                        } else {
                            date.validate();
                            total_month.validate();
                            total_bin.validate();
                            mode_payment.validate();
                        }
                    }
                    // Always allow step back to the previous step even if the current step is not valid.
                } else { return true; }
            }
        });

        initializeUserSearch();
        initializeProductSearch();
        initializeDatepicker();

        return () => {
            amount = 0;
            const wizard = jQuery('#wizard2');
            if(wizard.data('steps')) {
                wizard.steps('destroy');
            }
        };
    }, []);

    $(function () {
        $(document).on("change", "#address", function () {
            let $productSelect = $('#product-select2');
            $productSelect.empty();

            if($(this).val()) {
                $productSelect.prop("disabled", false);
            } else {
                $productSelect.prop("disabled", true);
            }
        });

        $(document).on("change", "#total-month", function (e) {
            let total_month = e.target.value;
            let total_bin = $("#total-bin").val();
            $("#total-amount").text(`${auth.user.country.currency.symbol}${formatNumber(amount * total_month * total_bin)}`);
        });

        $(document).on("change", "#total-bin", function (e) {
            let total_month = $("#total-month").val();
            let total_bin = e.target.value;
            $("#total-amount").text(`${auth.user.country.currency.symbol}${formatNumber(amount * total_month * total_bin)}`);
        });

        return () => {
            $(document).off("change", "#total-month");
            $(document).off("change", "#total-bin");
            $(document).off("change", "#address");
        };
    });

    const initializeUserSearch = () => {
        let modalUser = $('#modalSubscription');
        $('#user-select2').select2({
            placeholder: 'Select Customer',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalUser,
            ajax: {
                url: GET_ZONE_USER,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query ? query.term : null,
                        pageSize: 0
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data.data, function(item) {
                            return {
                                text: capitalize(`${item.first_name} ${item.last_name}`),
                                addresses: item.addresses,
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            let $addressSelect = $('#address');
            $addressSelect.prop('disabled', false);
            $addressSelect.empty();

            if (e.params.data.addresses.length === 0) {
                addresses = [];
                $addressSelect.append('<option value="" selected>No Address Available</option>');
            } else {
                $addressSelect.prop('disabled', false);
                addresses = e.params.data.addresses;
                $addressSelect.append('<option value="" selected>Select Address</option>'); // Default option
                $.each(e.params.data.addresses, function(index, address) {
                    $addressSelect.append('<option value="' + address._id + '-' + address.property + '-' + address.location.coordinates[0] + '-' + address.location.coordinates[1] +'">' + address.meta.street_address + '</option>');
                });
            }

            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function() {
            let $addressSelect = $('#address');
            let $productSelect = $('#product-select2');

            $addressSelect.empty();
            $productSelect.empty();

            $addressSelect.append('<option value="" selected>Select Customer First</option>');

            $addressSelect.prop('disabled', true);
            $productSelect.prop('disabled', true);
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const initializeProductSearch = () => {
        let modalSubscription = $('#modalSubscription');
        $('#product-select2').select2({
            placeholder: 'Select Product',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalSubscription,
            ajax: {
                url: GET_ZONE_PRODUCT_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function() {
                    let $addressSelect = $('#address').val().split(/(?<!-)-/);
                    return {
                        location: {
                            lat: $addressSelect[2],
                            lng: $addressSelect[3]
                        },
                        type: $("#type").val(),
                        property: $addressSelect[1]
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data.payload, function(item) {
                            return {
                                text: `${capitalize(item.name)} - ${auth.user.country.currency.symbol}${item.amount}`,
                                amount: item.amount,
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            amount = e.params.data.amount;
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function() {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const initializeDatepicker = () => {
        $("#date").datepicker({
            dateFormat: "dd/mm/yy", // Custom format
            changeMonth: true,
            changeYear: true,
            showAnim: "slideDown",
            minDate: 0
        });
    };

    return(
        <div id="wizard2">
            <h3>Customer</h3>
            <section className="parsley-style-1">
                <p className="mg-b-20">Fill all the information below.</p>
                <form id="wizard-form">
                    <div className="row row-sm">
                        <div id="userWrapper" className="form-group parsley-input col-md-12">
                            <div className="d-flex justify-content-between">
                                <label>Customer<span className="tx-danger">*</span></label>
                            </div>
                            <div className="parsley-select">
                                <select className="form-control form-select" id="user-select2" data-width="100%"
                                        multiple="multiple"
                                        data-parsley-class-handler="#userWrapper"
                                        data-parsley-errors-container="#userWrapper"
                                        autoComplete="off"
                                        required>
                                </select>
                            </div>
                        </div>

                        <div id="addressWrapper" className="form-group parsley-input col-md-12">
                            <label>Address<span className="tx-danger">*</span></label>
                            <select className="custom-select" id="address" required disabled>
                                <option value="" selected>Select Customer First</option>
                            </select>
                        </div>

                        <div id="typeWrapper" className="form-group parsley-input col-md-12">
                            <label>Type<span className="tx-danger">*</span></label>
                            <select className="custom-select" id="type" required>
                                <option value="" disabled selected>Select</option>
                                <option value="subscription">Subscription</option>
                            </select>
                        </div>

                        <div id="productWrapper" className="form-group parsley-input col-md-12">
                            <div className="d-flex justify-content-between">
                                <label>Product<span className="tx-danger">*</span></label>
                            </div>
                            <select className="form-control" id="product-select2" data-width="100%"
                                    multiple="multiple"
                                    data-parsley-class-handler="#productWrapper"
                                    data-parsley-errors-container="#productWrapper"
                                    autoComplete="off"
                                    required
                                    disabled
                            >
                            </select>
                        </div>
                    </div>
                </form>
            </section>
            <h3>Selection</h3>
            <section>
                <p>Fill all the information below.</p>
                <div className="row row-sm">
                    <div className="col-md-12 form-group">
                        <label className="form-control-label">Start Date: <span className="tx-danger">*</span></label>
                        <input id="date" className="form-control" name="date" placeholder="Select Date" type="text" autoComplete="off" required/>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="form-control-label">Number of Month: <span className="tx-danger">*</span></label>
                        <input id="total-month" className="form-control" name="total_month" placeholder="Enter number of month" min={1} defaultValue={1} type="number" required/>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="form-control-label">Number of Bin: <span className="tx-danger">*</span></label>
                        <input id="total-bin" className="form-control" name="total_bin" placeholder="Enter number of bin" min={1} defaultValue={1} type="number" required/>
                    </div>

                    <div id="papmentModeWrapper" className="form-group parsley-input col-md-12">
                        <label>Payment Mode<span className="tx-danger">*</span></label>
                        <select className="custom-select" id="mode-payment" required>
                            <option value="" disabled selected>Select</option>
                            <option value="momo">Mobile Money</option>
                            <option value="bank">Bank Transfer</option>
                            <option value="cash">Cash</option>
                        </select>
                    </div>

                    <div className="col-md-12 mt-2">
                        <div className="media">
                            <div className="wd-40 ht-40 rounded bd bd-2 bd-gray-500 d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                                <i data-feather="hash" className="wd-20 ht-20 tx-gray-500 stroke-wd-3"></i>
                            </div>
                            <div className="mg-l-10">
                                <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">Total Amount</p>
                                <h4 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-10" id="total-amount">K0</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <h3>Confirmation</h3>
            <section>
                <p>Ensure the details are correct before you proceed.</p>

                <div className="media">
                    <div className="wd-35 ht-35 rounded bd bd-2 bd-gray-500 d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                        <i data-feather="user" className="wd-20 ht-20 tx-gray-500 stroke-wd-3"></i>
                    </div>
                    <div className="mg-l-10">
                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">Customer Name</p>
                        <h5 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-10" id="tx-customer">Customer Name</h5>
                    </div>
                </div>

                <div className="media mt-3">
                    <div className="wd-35 ht-35 rounded bd bd-2 bd-gray-500 d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                        <i data-feather="map-pin" className="wd-20 ht-20 tx-gray-500 stroke-wd-3"></i>
                    </div>
                    <div className="mg-l-10">
                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">Address</p>
                        <h5 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-10" id="tx-address">27211, Libala Stage 3</h5>
                    </div>
                </div>

                <div className="media mt-3">
                    <div className="wd-35 ht-35 rounded bd bd-2 bd-gray-500 d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                        <i data-feather="box" className="wd-20 ht-20 tx-gray-500 stroke-wd-3"></i>
                    </div>
                    <div className="mg-l-10">
                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">Waste Type</p>
                        <h5 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-10" id="tx-waste-type">Subscription</h5>
                    </div>
                </div>

                <div className="media mt-3">
                    <div className="wd-35 ht-35 rounded bd bd-2 bd-gray-500 d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                        <i data-feather="calendar" className="wd-20 ht-20 tx-gray-500 stroke-wd-3"></i>
                    </div>
                    <div className="mg-l-10">
                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">Start Date</p>
                        <h5 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-10" id="tx-date">29th January, 2025</h5>
                    </div>
                </div>

                <div className="media mt-3">
                    <div className="wd-35 ht-35 rounded bd bd-2 bd-gray-500 d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                        <i data-feather="clock" className="wd-20 ht-20 tx-gray-500 stroke-wd-3"></i>
                    </div>
                    <div className="mg-l-10">
                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">Number of Month</p>
                        <h5 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-10" id="tx-total-month">1</h5>
                    </div>
                </div>

                <div className="media mt-3">
                    <div className="wd-35 ht-35 rounded bd bd-2 bd-gray-500 d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                        <i data-feather="trash" className="wd-20 ht-20 tx-gray-500 stroke-wd-3"></i>
                    </div>
                    <div className="mg-l-10">
                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">Number of Bin</p>
                        <h5 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-10" id="tx-total-bin">1</h5>
                    </div>
                </div>

                <div className="media mt-3">
                    <div className="wd-35 ht-35 rounded bd bd-2 bd-gray-500 d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                        <i data-feather="hash" className="wd-20 ht-20 tx-gray-500 stroke-wd-3"></i>
                    </div>
                    <div className="mg-l-10">
                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">Total Amount</p>
                        <h5 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-10" id="tx-total-amount">K0.00</h5>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Wizard;
