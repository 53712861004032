import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_AGGREGATE_DATA, GET_COLLECTION_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import cogoToast from "cogo-toast";
import {getAccountBalance, getFinanceReport, getZones} from "../../../api/app";
import moment from "moment";
import formatNumber from "../../../utils/formatNumber";
import {useSelector} from "react-redux";
import exportToExcel from "../../../utils/exporter";

let selectedArea = [];
let currentDateRange = null;
let total = 0;

const Settlement = () => {

    document.title = "Settlements";

    const auth = useSelector((state) => state.auth.user);
    const token = localStorage.getItem('jwtToken');
    const [loading, setLoading] = useState(true);
    const [exportLoading, setExportLoading] = useState(false);
    const [zones, setZones] = useState([]);
    const [randomTime, setRandomTime] = useState(new Date().getTime());

    useEffect(() => {
        getAccountBalance().then((response) => {
            $('#total-balance').text(auth.user.country.currency.symbol+formatNumber(response.payload.balance));
            $('#total-payout').text("-"+auth.user.country.currency.symbol+formatNumber(response.payload.totalPayoutAmount));
        })
    }, [randomTime]);

    useEffect(() => {
        let table = $('#td-transaction').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Settlement Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_AGGREGATE_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "payout",
                        searchableFields: [
                            { field: 'gateway', isNumeric: false },
                            { field: 'amount', isNumeric: true }
                        ],
                        pageSize: data.start,
                        format: 'json',
                        populate: [
                            {
                                path: "collector",
                                model: "collector"
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        total = res.total;
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${auth.user.country.currency.symbol}${formatNumber(data.amount)}`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return capitalize(data.gateway);
                    },
                    "targets": 1
                },
                {
                    "render": function () {
                        return `<span class="badge badge-success tx-white">Paid</span>`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.period.start.str).format("Do MMM, YYYY")
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.period.end.str).format("Do MMM, YYYY")
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY HH:mm")
                    },
                    "targets": 5
                }
            ]
        });

        return () => {
            selectedArea = [];
            currentDateRange = null;
            total = 0;
            table.destroy();
        }

    }, []);

    useEffect(() => {
        getZones().then((response) => {
            if(!response.error) {
                setZones(response.payload);
                setLoading(false);
            }
        })
    }, []);

    const exportData = () => {
        setExportLoading(true);
        $.ajax(GET_COLLECTION_DATA, {
            type: 'POST',
            headers: {
                "Authorization": token
            },
            data: {
                collection: "payout",
                fieldname: "type",
                limit: total,
                pageSize: 0,
                filter: {
                    collector: auth.user._id
                },
                format: 'json',
                populate: "collector"
            },
            success: function (res) {
                setExportLoading(false);
                const filteredData = res.data.map(item => ({
                    amount: `${auth.user.country.currency.symbol}${formatNumber(item.amount)}`,
                    gateway: capitalize(item.gateway),
                    status: "Paid",
                    start_period: moment(item.period.start.str).format("Do MMM, YYYY"),
                    end_period: moment(item.period.end.str).format("Do MMM, YYYY"),
                    date_issued: moment(item.date.str).format("Do MMM, YYYY"),
                }));

                const headers = [
                    "Amount",
                    "Source",
                    "Status",
                    "Start Period",
                    "End Period",
                    "Date Issued"
                ];

                exportToExcel(headers, filteredData, "Settlement");
            },
            error: function(err) {
                cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
            }
        });
    }

    const refreshTable = (e) => {
        e.preventDefault();
        setRandomTime(new Date().getTime())
        $('#td-transaction').DataTable().ajax.reload(null, false);
    }

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Settlements</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Settlements</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-12">
                        <div className="card card-body">
                            <div className="d-md-flex align-items-center justify-content-between">
                                <div className="media align-sm-items-center">
                                    <div className="tx-40 tx-lg-60 ht-55 lh-0">
                                        <a href="" className="avatar">
                                            <img src="assets/img/wallet.svg" className="rounded-circle" alt=""/></a>
                                    </div>
                                    <div className="media-body mg-l-15 my-auto">
                                        <h6 className="tx-12 tx-lg-14 tx-semibold tx-uppercase tx-spacing-1 mg-b-5">Withdraw Balance <span className="tx-normal tx-color-03">({auth.user.country.currency.code})</span></h6>
                                        <div className="d-flex align-items-baseline">
                                            <h2 className="tx-20 tx-lg-28 tx-normal tx-rubik tx-spacing--2 lh-2 mg-b-0" id="total-balance">K0.00</h2>
                                            <h6 className="tx-11 tx-lg-16 tx-normal tx-rubik tx-danger mg-l-5 lh-2 mg-b-0" id="total-payout">Checking Balance</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-column flex-sm-row mg-t-20 mg-md-t-0">
                                    {exportLoading ? <button disabled className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/> Exporting</button> : <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={exportData.bind()}><i className="wd-10 mg-r-5 fa fa-upload"></i> Export</button>}
                                    {/*<button className="btn btn-sm btn-white btn-uppercase pd-x-15 mg-t-5 mg-sm-t-0  mg-sm-l-5"><i className="mg-r-5 fa fa-eye"></i> Withdraw</button>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Settlements</h6>
                                <div className="d-flex tx-18">
                                    <a href="#" onClick={refreshTable.bind()} className="link-03 lh-0"><i
                                        className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-transaction" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-15p">Amount</th>
                                        <th className="wd-15p">Source</th>
                                        <th className="wd-15p">Status</th>
                                        <th className="wd-15p">Start Period</th>
                                        <th className="wd-15p">End Period</th>
                                        <th className="wd-15p">Date Issued</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete
                            </button>
                            <button type="button" className="btn btn-danger btn-block mt-0"
                                    data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Settlement;
