import React, {useEffect, useState} from 'react';
import {GET_COLLECTION_AGGREGATE_DATA, GET_COLLECTION_DATA, SEARCH_COLLECTION_URL} from "../../../api";
import 'datatables.net';
import 'datatables.net-responsive';
import 'components-jqueryui';
import $ from "jquery";
import 'select2';
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import { product } from "../../../api/app";
import {capitalize} from "../../../utils/capitalize";
import {useSelector} from "react-redux";

const Product = () => {

    document.title = "Products";

    const auth = useSelector((state) => state.auth.user);

    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [editId, setEditId] = useState(0);

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        let table = $('#product').DataTable({
            responsive: true,
            language: {
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Product Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: (data, callback) => {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_AGGREGATE_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "product",
                        filter: {
                            collector: auth.user.sub
                        },
                        searchableFields: [
                            { field: 'name', isNumeric: false },
                            { field: 'property.name', isNumeric: false },
                        ],
                        pageSize: data.start,
                        populate: [
                            {
                                path: 'collector',
                                model: 'collector',
                                populate: {
                                    path: 'country',
                                    model: 'country'
                                }

                            },
                            {
                                path: 'property',
                                model: 'property',
                            }
                        ],
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${capitalize(data.type)} - ${capitalize(data.property.name)}`
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${data.collector.country.currency.symbol}${data.amount}`
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.period === 1 ? `${data.period} Day` : `${data.period} Days`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "active" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "depreciate" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only">${data.status === "active" ? `<a href="#" class="nav-link edit_product"><i class="fa fa-pencil"></i> Edit</a>` : `<nav class="nav-link"><i class="fa fa-ban"></i> Closed</nav>`}<a href="#" class="nav-link delete_product"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.delete_product', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.type)} product?`);
            $('#delete-product').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                product("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success("Product successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        table.on('click', '.edit_product', function(e) {
            e.preventDefault();

            initializePropertySearch();

            let amount = $("#amount");
            let description = $("#description");
            let type = $("#type");

            let extract_td = table.row($(this).closest('tr')).data();
            let property_select = $("#property-select2");

            setEditForm(true);
            setEditId(extract_td.raw._id);

            $('#product_form').parsley();

            type.prop("disabled", true).prop("required", false);
            property_select.prop("disabled", true).prop("required", false);

            amount.val(extract_td.raw.amount);
            type.val(extract_td.raw.type);
            description.val(extract_td.raw.description);

            let $_option = $("<option selected></option>").val(extract_td.raw.property._id).text(capitalize(extract_td.raw.property.name));
            property_select.append($_option).trigger('change');

            $("#modalProduct").modal("show");
        });

        return () => {
            table.destroy();
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        let amount = $('#amount').val();
        let description = $('#description').val();

        setStateReady(true);

        if(editForm) {
            let data = {
                id: editId,
                amount,
                description
            };

            product("put", data).then((result) => {
                setStateReady(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalProduct", "product_form");
                    reloadTable();
                }
            });

            return false;
        }

        let data = {
            amount: amount,
            description: description,
            type: $('#type').val(),
            property: $('#property-select2').val()[0],
            collector: auth.user.sub
        };

        product("post", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalProduct", "product_form");
                reloadTable();
            }
        });
    };

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const handleShowModal = () => {
        setEditForm(false);
        $('#type').prop("disabled", false).prop("required", true);
        $('#property-select2').prop("disabled", false).prop("required", true);
        initializePropertySearch();
        $('#product_form').parsley();
        $("#modalProduct").modal({ backdrop: 'static', focus: false, show: true });
    };

    const reloadTable = () => {
        $('#product').DataTable().ajax.reload(null, false);
    };

    const initializePropertySearch = () => {
        let modalProduct = $('#modalProduct');
        $('#property-select2').select2({
            placeholder: 'Select property type or type name',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalProduct,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "property"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        });
    }

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="pages/authorized/settings/Product#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Products</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Products</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Product List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="product" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Type</th>
                                        <th className="wd-20p">Amount</th>
                                        <th className="wd-20p">Period</th>
                                        <th className="wd-20p">Status</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalProduct" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="pages/authorized/settings/Product" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalProduct", "product_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Product" : "Create Product"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} product information.</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="product_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                        <div id="propertyWrapper" className="form-group parsley-input">
                                            <div className="d-flex justify-content-between">
                                                <label>Property<span className="tx-danger">*</span></label>
                                            </div>
                                            <select className="form-control" id="property-select2" data-width="100%"
                                                    multiple="multiple"
                                                    data-parsley-class-handler="#propertyWrapper"
                                                    data-parsley-errors-container="#propertyWrapper"
                                                    autoComplete="off"
                                                    required>
                                            </select>
                                        </div>

                                        <div id="typeWrapper" className="form-group parsley-input">
                                            <label>Type<span className="tx-danger">*</span></label>
                                            <select className="custom-select" id="type" required>
                                                <option value="" disabled selected>Select</option>
                                                <option value="subscription">Subscription</option>
                                                <option value="pickup">Special Pickup</option>
                                            </select>
                                        </div>

                                        <div id="amountWrapper" className="form-group parsley-input">
                                            <label>Amount<span className="tx-danger">*</span></label>
                                            <input id="amount" type="number" className="form-control"
                                                   placeholder="Enter amount"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#amountWrapper" required/>
                                        </div>

                                        <div id="descriptionWrapper" className="form-group parsley-input">
                                            <label
                                                className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Description</label>
                                            <textarea id="description" rows="5" className="form-control"
                                                      placeholder="Provide a description"
                                                      data-parsley-class-handler="#descriptionWrapper"
                                                      required></textarea>
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ?
                                            <button className="btn btn-success btn-block">Update Product</button> :
                                            <button className="btn btn-success btn-block">Create Product</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content tx-14">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="mg-b-0 data-message">Empty</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="delete-product" className="btn btn-success btn-block">Yes, Delete</button>
                                    <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Product;
