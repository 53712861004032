import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-buttons';
import 'datatables.net-responsive';
import {GET_ZONE_USER} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import moment from 'moment';
import cogoToast from "cogo-toast";
import {getZones, user} from "../../../api/app";
import exportToExcel from "../../../utils/exporter";

let selectedArea = [];
let total = 0;

const Customer = (props) => {

    document.title = "Customers";

    const [zones, setZones] = useState([]);
    const [subdivisions, setSubdivisions] = useState([]);

    const token = localStorage.getItem('jwtToken');

    const [loading, setLoading] = useState(true);
    const [areaName, setAreaName] = useState("All Zones");
    const [subdivisionName, setSubdivisionName] = useState("Select");
    const [exportLoading, setExportLoading] = useState(false);
    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);

    useEffect(() => {
        getZones().then((response) => {
            if(!response.error) {
                setZones(response.payload);
                setLoading(false);
            }
        })
    }, []);

    useEffect(() => {
        var table = $('#td-user').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Customer Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_ZONE_USER, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        pageSize: data.start,
                        searchableFields: [
                            { field: "first_name", "isNumeric": false },
                            { field: "last_name", "isNumeric": false },
                            { field: "mobile_number", "isNumeric": true }
                        ],
                        geometry: selectedArea?.geometry,
                        format: 'json',
                        populate: [
                            {
                                path: "country",
                                model: "country"
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        total = res.total;
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${capitalize(data.first_name)} ${capitalize(data.last_name)}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.mobile_number
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return capitalize(data.gender)
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return {
                            true : `<i class="fa fa-check-circle tx-success"></i>`,
                            false : `<i class="fa fa-times-circle tx-danger"></i>`
                        }[data.subscribe];
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return moment(data.date.iso).format("Do MMM, YYYY");
                    },
                    "targets": 5
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href='#' class="nav-link view_user"><i class="fa fa-eye"></i> View</a></nav>`
                    },
                    "targets": 6
                }
            ]
        });

        table.on('click', '.view_user', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            props.history.push(`/customer/${extract_td.raw._id}`);
        });

        $('#td-user tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Last Login:</td>' +
                '<td>' +
                d.raw.last_login.str +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Country:</td>' +
                '<td>'+capitalize(d.raw.country[0].name)+'</td>' +
                '</tr>' +
                '</table>'
            );
        }

        return () => {
            table.destroy();
        }

    }, []);

    const exportData = () => {
        setExportLoading(true);
        $.ajax(GET_ZONE_USER, {
            type: 'POST',
            headers: {
                "Authorization": token
            },
            data: {
                pageSize: 0,
                limit: total,
                geometry: selectedArea?.geometry,
                format: 'json',
                populate: "country"
            },
            success: function (res) {
                setExportLoading(false);
                const filteredData = res.data.map(item => ({
                    first_name: capitalize(item.first_name),
                    last_name: capitalize(item.last_name),
                    mobile_number: item.mobile_number.toString(),
                    subscribed: item.subscribe ? "Yes" : "No",
                    date: moment(item.date.iso).format("Do MMM, YYYY")
                }));

                const headers = [
                    "First Name",
                    "Last Name",
                    "Mobile Number",
                    "Subscribed",
                    "Date Issued"
                ];

                exportToExcel(headers, filteredData, "Customer");
            },
            error: function(err) {
                cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
            }
        });
    }

    const onAreaSelected = (area)  => {
        area !== null ? selectedArea = area : selectedArea = null;
        area !== null ? setAreaName(area.name) : setAreaName("All Zones");
        area !== null ? setSubdivisions(area.subdivisions) : setSubdivisions([]);
        reloadTable();
    }

    const onSubdivisionSelected = (area)  => {
        area !== null ? selectedArea = area : selectedArea = null;
        area !== null ? setSubdivisionName(area.name) : setSubdivisionName("Select");
        reloadTable();
    }

    const handleUserSubmit = (e) => {
        e.preventDefault();
        setStateReady(true);

        let data = {
            first_name : $("#first_name").val(),
            last_name: $("#last_name").val(),
            gender : $("#gender").val(),
            mobile_number : $("#mobile").val()
        }

        user("post", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalUser", "user_form");
                reloadTable();
            }
        })

        return false;
    }

    const reloadTable = () => {
        $('#td-user').DataTable().ajax.reload();
    };

    const handleShowModal = () => {
        setEditForm(false);
        $('#user_form').parsley();
        $("#modalUser").modal({ backdrop: 'static', focus: false, show: true });
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Customers</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Customers</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                        {exportLoading ? <button disabled className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/> Exporting</button> : <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={exportData.bind()}><i className="wd-10 mg-r-5 fa fa-upload"></i> Export</button>}
                        {loading ? <button disabled className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-l-5 outline-none"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/> Collecting</button> :
                            <span className="dropdown">
                                <button className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-l-5 outline-none dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="wd-10 mg-r-5 fa fa-globe"></i> {areaName}</button>
                                <div className="dropdown-menu" style={{maxHeight: 356, overflowY: "auto"}}>
                                    <a href="#" onClick={(e) => {e.preventDefault(); onAreaSelected(null)}} className="dropdown-item">All Zones</a>
                                    {zones.map((item, index) => {
                                        return(
                                            <a href="#" key={index} onClick={(e) => {e.preventDefault(); onAreaSelected(item)}} className="dropdown-item">{capitalize(item.name)}</a>
                                        )
                                    })}
                                </div>
                            </span> }
                            <span className="dropdown">
                                <button className="btn btn-sm pd-x-15 btn-secondary btn-uppercase mg-l-5 outline-none dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="wd-10 mg-r-5 fa fa-globe"></i> {subdivisions?.length ? subdivisionName : "Select"}</button>
                                <div className="dropdown-menu" style={{maxHeight: 356, overflowY: "auto"}}>
                                    {subdivisions?.length ? subdivisions.map((item, index) => {
                                        return(
                                            <a href="#" key={index} onClick={(e) => {e.preventDefault(); onSubdivisionSelected(item)}} className="dropdown-item">{capitalize(item.name)}</a>
                                        )
                                    }) : <span className="dropdown-item">Select Zone First</span>}
                                </div>
                            </span>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Customers</h6>
                                <div className="d-flex tx-18">
                                    <a href="#" onClick={(e) => {e.preventDefault(); reloadTable()}} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-user" className="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Mobile Number</th>
                                        <th className="wd-15p">Gender</th>
                                        <th className="wd-15p">Subscribed</th>
                                        <th className="wd-15p">Date Created</th>
                                        <th className="wd-15p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalUser" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15"
                                   data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalUser", "user_form")} aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit User" : "New User"}</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} user information.</p></div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="user_form" className="parsley-style-1" onSubmit={handleUserSubmit.bind()}>
                                    <div id="mobileWrapper" className="form-group parsley-input">
                                        <label>Mobile Number<span className="tx-danger">*</span></label>
                                        <input id="mobile" type="number" className="form-control"
                                               placeholder="Enter mobile number"
                                               autoComplete="none"
                                               data-parsley-class-handler="#mobileWrapper" required/>
                                    </div>

                                    <div id="firstNameWrapper" className="form-group parsley-input">
                                        <label>First Name<span className="tx-danger">*</span></label>
                                        <input id="first_name" type="text" className="form-control"
                                               placeholder="Enter first name"
                                               autoComplete="none"
                                               data-parsley-class-handler="#firstNameWrapper" required/>
                                    </div>

                                    <div id="lastNameWrapper" className="form-group parsley-input">
                                        <label>Last Name<span className="tx-danger">*</span></label>
                                        <input id="last_name" type="text" className="form-control"
                                               placeholder="Enter last name"
                                               autoComplete="none"
                                               data-parsley-class-handler="#lastNameWrapper" required/>
                                    </div>

                                    <div id="genderWrapper" className="form-group parsley-input">
                                        <label>Gender<span className="tx-danger">*</span></label>
                                        <select className="custom-select" id="gender" required>
                                            <option value="" disabled selected>Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>

                                    {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ?
                                        <button className="btn btn-success btn-block">Update User</button> : <button className="btn btn-success btn-block">Create User</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Customer;
